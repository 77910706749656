
import { defineComponent } from "vue";
import MotorPolicyInformation from "@/views/reusable/policies/policy-information/motor-insurance/PolicyInformation.vue";

export default defineComponent({
  name: "broker-client-motor-insurance-policy-information",
  components: {
    MotorPolicyInformation
  },
  created() {
    //Set page title
    document.title = "Motor Policy Information | " + process.env.VUE_APP_TITLE;
  }
});
